﻿/*-------------------------
    Invitados
--------------------------*/

/*----------------------
        ESTRUCTURA
------------------------*/


body{  
    font-family: 'Lato';
    font-weight: normal;
    background-color: #f2f4f7;
    font-size: 14px;
    font-weight: $fw-regular;
    color: $c-ter-700;
}


/*----------------------
        RESETS
------------------------*/

* {
    scrollbar-width: thin;
    scrollbar-color: $c-ter-500 #f2f4f7;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

*::-webkit-scrollbar-track {
    background: #f2f4f7;
}

*::-webkit-scrollbar-thumb {
    background-color: $c-ter-500;
    border-radius: 20px;
    border: 0px none #f2f4f7;
}


.main-container {
    padding: 0 7% 2% 7%;
}

h1 {
    font-size: 28px;
    line-height: 34px;
}

h2 {
    font-size: 24px;
    line-height: 30px;
}

h3 {
    font-size: 20px;
    line-height: 26px;
}

h4 {
    font-size: 15px;
    line-height: 20px;
}

h1,
h2,
h3,
h4 {
    font-weight: 900;
    color: $c-ter-700;

}

.navBarRGPD{
    padding: 0 0 0 0;
}

.navbar {

    &.bg-main-bar {
            background-color: #fff;
            height: 64px;
    }

    .navbar-brand {
            padding: 0;
            margin: .2rem 1.5rem 0;
    }

    .logo {
            font-weight: $fw-heavy;
            display: flex;
            align-items: center;

            .logo-full {
                    background: url('./images/logo-full.svg') center center no-repeat;
                    width: 81px;
                    height: 19px;
            }
    }

    &.navbar-light {
            .navbar-nav {
                    .nav-link {
                            font-weight: $fw-heavy;
                            color: $c-ter-700;
                            font-size: 15px;
                            padding: 0 1.5rem;

                            &:hover,
                            &.active {
                                    color: $c-prim;
                            }
                    }
            }
    }

    .dropdown {
            .dropdown-menu {
                    top: 30px !important;
                    right: 15px !important;
            }
    }

}

.Toastify {
    .Toastify__toast-container {
            width: 388px;

            .Toastify__toast {
                    border-radius: 8px;
                    box-shadow: 0 2px 10px 0 rgba(8, 32, 66, 0.24);
                    font-family: 'Lato';
                    font-weight: $fw-bold;
                    color: $c-ter-700;
                    padding: 28px;

                    .Toastify__toast-icon {
                            font-size: 16px;
                            margin-right: 1rem;

                            .fticon-confirm {
                                    color: $c-sucs;
                            }

                            .fticon-state-cancel {
                                    color: $c-error;
                            }
                    }
            }

    }
}

.Landing {
    width: 100%;
    display: flex;
    justify-content: center;
}

/*DIV IZQUIERDO*/
.Rectngulo-37-izq {
    max-width: 400px;
    margin: 34px 10px 18px 2px;
    padding: 0 0 39px;
    border-radius: 14px;
    box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
    background-color: #fff;
    display: inline;
}

.poster-cuadrado {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 40px;
    border-radius: 14px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 450px;
    background-position: top center;
    border-radius: 14px 14px 0px 0px;
}

.content-info {
    margin: 0 auto;
    width: 85%;
}

.event-title {
    // width: 258px;
    margin: 0 3% 9px 0;
    font-family: 'Lato';
    font-size: 26px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
}

.number-invitations {
    // width: 202px;
    height: 19px;
    margin: 9px 3% 40.6px 0;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #657488;
}

.Grupo-102 {
    // width: 258px;
    height: 40.4px;
    margin: 40.6px 3% 21px 0;
    text-align: center;
    display:flex
}

.line-izq {
    // max-width: 80%;
    height: 1px;
    margin: 21px 3% 24px 0px;
    opacity: 0.4;
    border-radius: 8px;
    background-color: #bfcbd7;
}

.Grupo-103 {
    // width: 258px;
    margin: 24px 3% 0 0;
    text-align: center;
    display: flex
}

.iconcalendar {
    width: 15.6px;
    height: 17.3px;
    margin: 0px 18.4px 12.7px 0;
    display: inline;
}

.event-date {
    width: 100%;
    height: 19px;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
    display: inline;
}

.event-time {
    width: 44px;
    height: 17px;
    margin: 4.4px 105px 0 18.4px;
    font-family: 'Lato';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #657488;
}

.iconlocation {
    width: 14px;
    height: 18px;
    margin: 0px 19px 12px 0;
    border: solid 1px rgba(0, 0, 0, 0);
    display: inline;
}

.event-site {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
    display: block;
}

.event-address {
    font-family: 'Lato';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #657488;
    display: block;
}

/*DIV DERECHO*/
.Rectngulo-37-dch {
    margin: 34px 0 18px 2px;
    padding: 25px 0px 55px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
    background-color: #fff;
    display: inline;
}

.header {
    width: 660px;
}
.header-text {
    margin: 0 5% 23px 7%;
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
    display:block;
}

.line-dch {
    width: 100%;
    height: 1px;
    opacity: 0.4;
    border-radius: 8px;
    background-color: #bfcbd7;
}

.guest-name {
    margin: 53px 9% 16px 9%;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
}

.invitation-text {
    width: 514px;
    height: 35px;
    margin: 16px 9% 40px 9%;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
}

.titulo-asistencia {
    width: 136px;
    height: 18px;
    margin: 40px 9% 10px 9%;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
}

.container-confirm {
    display: flex;
    margin: 0px 9%;
}

.Rectngulo-confirm-yes-off {
    width: 245px;
    height: 113px;
    margin: 26px 29px 40px 0px;
    padding: 25px 36px 26px 37px;
    border-radius: 10px;
    border: solid 1px #bfcbd7;
    background-color: #fff;
    display: inline;
    cursor: pointer;
    
    &:hover{
        border: solid 1px #6dd845;
        .fticon-confirm {
            color: #6dd845;
        }
    }
}

.Rectngulo-confirm-yes-on {
    width: 245px;
    height: 113px;
    margin: 26px 29px 40px 0px;
    padding: 25px 36px 26px 37px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0px #6dd845;
    background-color: #fff;
    display: inline;
    .fticon-confirm {
        color: #6dd845;
    }
}

.Rectngulo-confirm-no-off {
    width: 245px;
    height: 113px;
    margin: 26px 77px 40px 0px;
    padding: 25px 40px 26px 41px;
    border-radius: 10px;
    border: solid 1px #bfcbd7;
    background-color: #fff;
    cursor: pointer;
    &:hover{
        border: solid 1px #ffa0ab;
        .fticon-state-cancel{
            color: #d90a44;
        }
    }
}

.Rectngulo-confirm-no-on {
    width: 245px;
    height: 113px;
    margin: 26px 77px 40px 0px;
    padding: 25px 40px 26px 41px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0px #ffa0ab;
    background-color: #fff;
    .fticon-state-cancel{
        color: #d90a44;
    }
}

.Trazado-confirm-yes-off {
    width: 27px;
    height: 27px;
    margin: 0 73px 17px 72px;
    color: #93a6b9;
    
}

.Trazado-confirm-yes-on {
    width: 27px;
    height: 27px;
    margin: 0 73px 17px 72px;
    color: #6dd845;
}

.Trazado-confirm-no-on {
    width: 28px;
    height: 28px;
    margin: 0 68px 16px;
    color: #d90a44;
}

.Trazado-confirm-no-off {
    width: 28px;
    height: 28px;
    margin: 0 68px 16px;
    color: #93a6b9;
}

.text-confirm-yes {
    width: 172px;
    height: 18px;
    margin: 17px 0 0;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #657488;
}

.text-confirm-no {
    width: 164px;
    height: 18px;
    margin: 16px 0 0;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #657488;
}

.observations {
    width: 99px;
    height: 18px;
    margin: 10px 9% 8px 9%;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
}

.Rectngulo-observations {
    width: 514px;
    height: 125px;
    margin: 16px 9% 20px 9%;
    opacity: 0.7;
    border-radius: 4px;
    border: solid 1px #bfcbd7;
    background-color: #f2f4f7;
    overflow: hidden;
}

.btn-response {
    margin: 10px 77px 20px 400px;
    padding: 14px 35px;
    border-radius: 4px;
    background-color: #1d3868 !important;
    border-color:#1d3868 !important;
    font-family: "Lato";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.send-response {
    width: 102px;
    height: 17px;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

/*INVITACION CONFIRMADA*/
.Rectngulo-confirm-dch {
    margin: 34px 0 32px 0px;
    padding: 25px 0 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
    background-color: #fff;
}
.invitation-register {
    margin: 16px 9% 30px;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
}

.Rectngulo-confirm {
    margin: 30px 9% 16px;
    padding: 23px 25px 23px 30px;
    border-radius: 10px;
    border: solid 1px #bfcbd7;
    background-color: #fff;
}
.check-confirm {
    margin: 0 -9px 0 0;    
    display: inline;
    color: #6dd845;
}

.check-noconfirm {
    margin: 0 -9px 0 0;    
    display: inline;
    color: #d90a44;
}

.Confirmo-asistencia {
    margin: 4px 0 5px 24px;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
    display: inline;
}
.observations-confirm {
    margin: 15px 9% 0;
    padding: 27px 55px 28px 34px;
    border-radius: 10px;
    border: solid 1px #bfcbd7;
    background-color: #fff;
    text-align: left;
    display: flex;
}

.download-area {
    margin: 30px 9% 0;
}

.Unin-2 {
    width: 20.7px;
    height: 20px;
    margin: 0 23px 0 0;
    border: solid 1px rgba(0, 0, 0, 0);
    display: inline;
}

.observations-text-confirm {
    // width: 386px;
    // height: 39px;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
    display: inline;
}

.Rectngulo-info-entradas {
    margin: 32px 0 16px 4px;
    padding: 24px 28px 24px 16px;
    border-radius: 8px;
    background-color: #D5F5E3/*#2ba000*/;
    text-align: center;
    display: flex;
}

.icon-entrada {
    margin: 0px 10px 29px 10px;
    border: solid 1px rgba(0, 0, 0, 0);
    display: inline;
    color: #2ba000;
}

.text-info-entradas {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
    display: inline;
}

.text-info-entradas-bold {
    font-weight: bold;
}

.Rectngulo-info-protocol {
    margin: 16px 0 18px 1px;
    padding: 24px 28px 24px 16px;
    border-radius: 8px;
    background-color: #D6EAF8/*#1376a5*/;
    text-align: center;
    display: flex;
}
.Rectngulo-info-protocol-accept-fueraDePlazo {
    margin: 16px 0 18px 1px;
    padding: 24px 28px 24px 16px;
    border-radius: 8px;
    background-color: #f7daa9/*#1376a5*/;
    text-align: center;
    display: flex;
}

.icon-info-protocol {
    width: 24px;
    height: 24px;
    margin: 0px 10px 29px 10px;
    border: solid 1px rgba(0, 0, 0, 0);
    display: inline;
    color: #1376a5;
}
.icon-info-protocol-accept-fueraDePlazo {
    width: 24px;
    height: 24px;
    margin: 0px 10px 29px 10px;
    border: solid 1px rgba(0, 0, 0, 0);
    display: inline;
    color: #f79b08;
}

.text-protocol {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
    display: inline;
}

.text-protocol-blue-bold {
    font-weight: bold;
    color: #1376a5;
}

.text-protocol-bold {
    font-weight: bold;
}


#block_container {
    text-align: center;
}

#bloc1, #bloc2 {
    display: inline;
}

.btn-prim-light {
    background-color: transparent !important;
    border: 1px solid $c-prim-dark;
    outline: none;
    box-shadow: none;
    color: $c-prim;
    padding: 8px 29px 8px 26px;
    border-radius: 19.5px;
    margin: 10px;

    &:hover {
            border-color: $c-prim-dark;
            color: $c-prim-dark;
            box-shadow: 0px 0px 1px 2px rgba($c-prim, .2) !important;
    }

    &:focus {
            border-color: $c-prim-dark;
            color: $c-prim-dark;
            box-shadow: none;
    }

    &:focus:hover {
            box-shadow: 0px 0px 1px 2px rgba($c-prim, .2) !important;
    }


    &:disabled,
    &.disabled {
            border-color: $c-prim-light;
            color: rgba($c-prim-dark, .6);
    }

}

.btn-dark {
    background-color: $c-dark-blue;
    border: 1px solid $c-dark-blue;
    outline: none;
    box-shadow: none !important;
    color: #fff;
    font-weight: $fw-bold;
    font-size: 14px;
    line-height: 1.5;

    &:hover,
    &:focus:hover {
            background-color: $c-sec-dark;
            border-color: $c-sec-dark;
    }

    &:focus {
            background-color: $c-sec;
            border-color: $c-sec;
    }

    &:disabled,
    &.disabled {
            background-color: $c-sec-light;
            color: #fff;
            border-color: $c-sec-light;
    }

}

//error 

.denied-page {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .ball {
            width: 62px;
            height: 62px;
            background-color: $c-prim;
            border-radius: 50%;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 2rem auto;

            i {
                    color: #fff;
                    font-size: 28px;
            }

    }
}

//Mobile 
.Landing-mobile {
    width: 390px;
    padding: 22px 20px 26px;
  }

  .Rectngulo-mobile{
    margin: 23px 0 0;
    padding: 29px 24px 26px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
    background-color: #fff;
  }

.invitacion-text-mobile {
  margin: 16px 9% 24px 9%;
  font-family: 'Lato';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #082042;
}

.guest-name-mobile {
    margin: 0 9% 16px 9%;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
}

.titulo-asistencia-mobile {
    margin: 24px 9% 20px 9%;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
  }

.container-confirm-mobile {
    padding: 0px 9% 21px 9%;
    display: inline-block;
    justify-content: center;
}

.Observaciones-mobile {
    margin: 0px 9% 0px 9%;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
  }

  .Rectngulo-observations-mobile {
    width: 302px;
    margin: 16px 9% 24px 9%;
    padding: 6px 20px 12px 18px;
    opacity: 0.7;
    border-radius: 4px;
    border: solid 1px #bfcbd7;
    background-color: #f2f4f7;
  }

  .btn-response-mobile {
    width: 302px;
    height: 44px;
    margin: 24px 9% 0 9%;
    border-radius: 4px;
    background-color: #1d3868;
  }

.Rectngulo-confirm-no-on-mobile {
  width: 302px;
  height: 68px;
  margin: 20px 0 16px;
  padding:20px 11px 26px 22px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px 0px #ffa0ab;
    .fticon-state-cancel{
        color: #d90a44;
    }
}

.Rectngulo-confirm-no-off-mobile {
    width: 302px;
    height: 68px;
    margin: 20px 0 16px;
    padding: 20px 11px 26px 22px;
    border-radius: 10px;
    border: solid 1px #bfcbd7;
    background-color: #fff;
    cursor: pointer;
    &:hover{
        border: solid 1px #ffa0ab;
        .fticon-state-cancel{
            color: #d90a44;
        }
    }
}

.Rectngulo-confirm-yes-on-mobile {
    width: 302px;
    height: 68px;
    padding:20px 11px 26px 22px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0px #6dd845;
    background-color: #fff;
    .fticon-confirm {
        color: #6dd845;
        font-size: 20px;
    }
}

.Rectngulo-confirm-yes-off-mobile {
    width: 302px;
    height: 68px;
    padding: 20px 11px 26px 22px;
    border-radius: 10px;
    border: solid 1px #bfcbd7;
    background-color: #fff;
    cursor: pointer;
    
    &:hover{
        border: solid 1px #6dd845;
        .fticon-confirm {
            color: #6dd845;
            font-size: 20px;
        }
    }
}

.text-mobile {
    width: 198px;
    height: 18px;
    margin: 17px 0 0;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #657488;
    display: inline;
}

.Trazado-mobile {
    width: 27px;
    height: 27px;
    margin: 0 10px 0px 0px;
    color: #93a6b9;
    display: inline; 
}

 .logo{
    width: 139.7px;
    height: 60px;
    margin: 0px 118px 11px 82px;
 }

 .texto-formulario{
        width: 267px;
        height: 50px;
        margin: 14px 0px 0px -54px;
        font-family: 'Lato';
        font-size: 20px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
        color: #082042;
 }

 //email RGPD
 .Rectngulo-112 {
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
  }

 .texto-baja {
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #082042;
    margin-bottom: .8rem;
  }

  .texto-baja-info{
    font-family: 'Lato';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #082042;
    margin-bottom: 1.5rem;
  }

  .btn-prim-dark {
    background-color: $c-prim;
    border: 1px solid $c-prim;
    outline: none;
    box-shadow: none !important;
    color: #fff;
    display: inline;
    padding: 8px 29px 8px 26px;
    border-radius: 19.5px;
    margin: 20px;

    &:hover,
    &:focus:hover {
            background-color: $c-prim-dark;
            border-color: $c-prim-dark;
    }

    &:focus {
            background-color: $c-prim;
            border-color: $c-prim;
    }


    &:disabled,
    &.disabled {
            background-color: $c-prim-light;
            color: #fff;
            border-color: $c-prim-light;
    }
}

.Rectngulo-nombre {
    margin: 14px 22px 0;
    padding: 22px 22px 22px 25px;
    border-radius: 6px;
    border: solid 1px #e2e9f0;
    background-color: #fff;
  }

  .texto-nombre-invitado {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
    display: inline-flex;
    float: left;
  }

  .Invitado {
    font-family: 'Lato';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #082042;
    margin: 0px 35px 0;
  }

  .switch{
    display: inline-flex;
    float: right;
  }

  .card {
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
    background-color: #fff;
    border: none;
}

.icons-baja{
    font-size: 50px;
    margin-bottom: 1.5rem;

    .fticon-mail {
        color:$c-ter-400;
    }
    .fticon-search {
        color:$c-ter-400;
    }
}

.qrs-generados {
    border: solid 1px #bfcbd7;
}

.check-baja {
    color: #6dd845;
    font-size: 60px;
}