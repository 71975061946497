/*----------------------
        FUENTES
------------------------*/

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato-Heavy.woff2') format('woff2'),
        url('./fonts/Lato-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato-Bold.woff2') format('woff2'),
        url('./fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato-Regular.woff2') format('woff2'),
        url('./fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato-Light.woff2') format('woff2'),
        url('./fonts/Lato-Light.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

$fw-heavy:900;
$fw-700:700;
$fw-medium:500;
$fw-regular:normal;
$fw-bold:bold;
