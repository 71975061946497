/*----------------------
        VARIABLES
------------------------*/

// PALETA

$c-prim: #ff345b;
$c-prim-dark: #d90a44;
$c-prim-light: #ffa0ab;

$c-sec: #4598bf;
$c-sec-dark: #1376a5;
$c-sec-light: #9bcbe2;
$c-dark-blue: #1d3868;

$c-ter-700: #082042;
$c-ter-600: #657488;
$c-ter-500: #93a6b9;
$c-ter-400: #bfcbd7;
$c-ter-300: #e2e9f0;
$c-ter-200: #f2f4f7;
$c-ter-100: #f8f9fa;

$c-sucs-dark: #2ba000;
$c-sucs: #6dd845;
$c-pend: #ffb134;
$c-error: #d90a44;

$c-blue: #1376a5;
$c-purple: #bc60e6;
$c-lightbl: #3ebad8;
$c-orange: #ff851c;
$c-yellow: #feb223;
$c-green: #6dd845;
$c-olive: #b1961c;
$c-pink: #ff60b2;
$c-red: #ff345b;
$c-reduced: #2972fa;

//dashboard
$c-greenlight : #7dcb60;
$c-redlight: #ff6b6b;
$c-purplelight: #7f77e0;

// BADGES

$bg-blue: $c-sec;
$bg-purple: #b36bd4;
$bg-orange: #f8915b;
$bg-yellow: #ba9e00;
$bg-green: #6f9800;
$bg-grey: $c-ter-600;
$bg-pink: #f26fb2;
$bg-darkblue: #4573d2;
$bg-red: #ff6286;

// ETIQUETAS

$et-send : #74d153;
$et-draft : $c-ter-500;
$et-open : $c-sucs-dark;
$et-bounce : $c-error;
$et-pend : $c-sec;

// TX COLOR
.tx-sucs-dark {
        color: $c-sucs-dark;
}

.tx-pend {
        color: $c-pend;
}


